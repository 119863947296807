import React, {useState} from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/ChangePassword.module.css';

const ChangePassword = (props) => {
    // Password
    const[oldPass, setOldPass] = useState('');
    const[newPass, setNewPass] = useState('');
    const[confirmNewPass, setConfirmNewPass] = useState('');
    const[passMessage, setPassMessage] = useState(null);

    // Change Password
    function setNewPassword(e) {
        e.preventDefault();

        // Verify Confirm Password Matches
        if (newPass !== confirmNewPass) {
            setPassMessage("Pins do not match!")
            return;
        }

        // Make API Call
        axios({
            method: 'post',
            url: '/auth/changePassword',
            withCredentials: true,
            data: {
                oldPass: oldPass,
                newPass: newPass 
            }
            })
            .then(function(res) {
                // If Success
                if(res.status === 200) {  
                    setPassMessage('Pin Updated')
                }
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 401) {
                    setPassMessage('Old Pin not Correct')
                }
            });

    }
    return (
        <div className={style.profileModal}>
            <div className={style.profileModalContent}>
                <div className={style.infoBar}>
                    <button onClick={e => {props.setShowEditPassword(false)}} className={style.closeButton}>X</button>
                </div>
                <form onSubmit={setNewPassword}>
                    <h3>Change PIN</h3>
                    <label>Current PIN</label>
                    <input type="password" placeholder='PIN' maxLength={4} 
                    required autoComplete='current-password' ip="pin"
                    onChange={e => setOldPass(e.target.value)} />

                    <label>New PIN</label>
                    <input type="password" placeholder='PIN' maxLength={4} 
                    required autoComplete='new-password' ip="pin"
                    onChange={e => setNewPass(e.target.value)} />

                    <label>Confirm New PIN</label>
                    <input type="password" placeholder='PIN' maxLength={4} 
                    required autoComplete='new-password' ip="pin"
                    onChange={e => setConfirmNewPass(e.target.value)} />
                    <input type="submit" value={"Update"}/>
                    {passMessage &&
                        <p>{passMessage}</p>
                    }
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;