import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

//Components
import Navbar from './Navbar';

// Style & Assets
import style from '../assets/style/PostModal.module.css';
import Post from './Post';

const PostModal = (props) => {
    const navigate  = useNavigate();
    let params = useParams();
    const[post, setPost] = useState('');

    // Get Post Data
    useEffect(() => {

        axios({
            method: 'get',
            url: '/getPost',
            withCredentials: true,
            params: 
                {
                postID: params.postID,
                },
            })
            .then(function(res) {
                if (res.status === 200) {
                    setPost(res.data[0])
                } else {
                    navigate("/feed")
                }
            })
            .catch(error => {
                // If 401 Ignore
                if (error.response.status === 401) {
                    navigate("/")
                    return
                }
            });
    },[navigate, params.postID])

    return (
        <div onClick={e => {navigate("/feed")}}>
            {props.isPage &&
                <Navbar user={props.user} />
            }
            <div className={style.postModal}>
                <div className={style.postModalContent} onClick={e => {}}>
                    <button>X</button>
                    {post
                    ?   <Post user={props.user} post={post} removePostFromFeed={props.removePostFromFeed}/>
                    :   <div><h3>No Post Found</h3></div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PostModal;