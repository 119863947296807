import React from 'react';

// Style & Assets
import style from '../assets/style/File.module.css';
import Logo from "../assets/logo.png";

const Files = () => {
    return (
        <div className={style.filePage}>
            <img src={Logo} alt="logo" />
            <div className={style.fileList}>
                <a href="/downloads/AriaHotelMap.pdf" download>Aria Hotel Map</a>
                <a href="/downloads/Booth Location Map.pdf" download>Booth Location Map</a>
                <a href="/downloads/Check In Map.pdf" download>Check In Map</a>
                <a href="/downloads/Las Vegas Convention Center Map.pdf" download>Las Vegas Convention Center Map</a>
                <a href="/downloads/Level 1 Meeting Room.pdf" download>Level 1 Meeting Room</a>
                <a href="/downloads/NACS-NSM Itinerary.pdf" download>{"NACS & NSM Itinerary"}</a>
                <a href="/downloads/Bullet Point Itinerary.pdf" download>Bullet Point Itinerary</a>
                <a href="/downloads/NSM Meeting Agenda.pdf" download>NSM Meeting Agenda</a>
                <a href="/downloads/NACS TRADESHOW BOOTH SCHEDULE.pdf" download>NACS Tradeshow Booth Schedule</a>
            </div>
        </div>
    );
};

export default Files;