import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Stats from '../components/Stats';
import Navbar from '../components/Navbar';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/StatsPage.module.css';
import StatsTable from '../components/StatsTable';

const StatsPage = (props) => {
    const navigate = useNavigate();
    const[view, setView] = useState(0)
    const[data,setData] = useState([])

    useEffect(() => {
        if (props.loading) {
            return
        }
        if (!props.user || props.user.permissionLevel < 2) {
            navigate("/feed")
        }

        // Get Data
        axios({
            method: 'get',
            url: '/getStats',
            withCredentials: true
        }).then(function(res) {
            setData(res.data)
        }).catch(error => {
            console.log(error)
        });

    }, [navigate, props.loading, props.user])

    return (
        <div>
            <Navbar user={props.user} />
            <div className={style.statsPageDiv}>
                <h2>Stats Dashboard</h2>
                <select className={style.viewSelect} value={view} onChange={e => {setView(parseInt(e.target.value))}}>
                    <option value={0}>Site Wide Data</option>
                    <option value={1}>User Specific Data</option>
                </select>
                
                <hr />
                {data &&
                    <>
                    {!view ?
                    <Stats stats={data}/>
                    :
                    <StatsTable user={props.user} stats={data}/>
                    }
                    </>
                }

            </div>
        </div>
    );
};

export default StatsPage;