import React from 'react';

/* Style & Assets */
import style from '../assets/style/BottomNavbar.module.css';

import HomeTrue from '../assets/home-true.svg';
import HomeFalse from '../assets/home-false.svg';

import PlusImage from "../assets/plus.svg";
import BellImage from "../assets/bell.svg";

import ProfileTrue from '../assets/profile-true.svg';
import ProfileFalse from '../assets/profile-false.svg';
import { Link } from 'react-router-dom';

const BottomNavbar = (props) => {
    return (
        <div className={style.bottomNavbar}>
            {props.currentPage === "home"
            ?<img src={HomeTrue} alt="Home" onClick={props.toTop}/>
            :<Link to={"/feed"}><img src={HomeFalse} alt="Home"/></Link>
            }
            {
            <Link to={"/newpost"}><img src={ PlusImage} alt={"Create Post"}/></Link>
            }
            {props.currentPage === "profile"
            ?<img src={ProfileTrue} alt="Profile"/>
            :<Link to={"/profile/"+props.userID}><img src={ProfileFalse} alt="Profile"/></Link>
            }
            <img src={BellImage} alt="Coming soon" onClick={e => {alert("Coming Soon")}}/>
        </div>
    );
};

export default BottomNavbar;