import React, { useState } from 'react';
import axios from 'axios'
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import style from '../assets/style/Login.module.css';

const Login = (props) => {
    const navigate = useNavigate();

    const[phone, setPhone] = useState('');
    const[password, setPassword] = useState('');
    
    // Error States
    const[isError, setIsError] = useState(false); 
    const[errorMessage, setErrorMessage] = useState('')
    
    function login(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/auth/login',
            withCredentials: true,
            data: {
              phone: phone,
              pass: password
            }
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status === 200) {   
                navigate('/feed')
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
                setIsError(true)
                setErrorMessage("Incorrect Phone Number or PIN")
            } else if (error.response.status === 403) {
                setIsError(true)
                setErrorMessage("Your account has been locked, please contact an admin for additional information")
            } else {
                setIsError(true)
                setErrorMessage("There has been an error")
            }
          });
    }

    
    return (
        <div className={style.loginModal} id={"LoginModal"} onKeyPress={(e) => {if (e.key === "Enter") {console.log("LOGGING IN");login(e)}}}>
            <div className={style.loginModalContent}>
                <form onSubmit={login}>
                    <div className={style.topBar}>
                        <div></div>
                        <h3>Login</h3>
                        <button className={style.closeButton} onClick={e => {props.toggleLoginModal()}}>X</button>
                    </div>

                    <label>Phone Number</label>
                    <InputMask
                        mask='999-999-9999'
                        value={phone} 
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Phone Number" 
                        required autoComplete='username'/>

                    <label>Pin</label>
                    <input type="password" placeholder='PIN' maxLength={4} 
                        required autoComplete='current-password' ip="pin"
                        onChange={e => setPassword(e.target.value)} />
                    
                    {isError &&
                        <p className={style.error}>{errorMessage}</p>
                    }

                    <input type="submit" value="Login" autoFocus/>
                </form>
            </div>
        </div>
    );
};

export default Login;