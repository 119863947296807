import React from 'react';

// Style & Assets
import style from '../assets/style/Stats.module.css';

const Stats = (props) => {
    return (
        <div className={style.statsSection}>
            <h2>Site Statistics</h2>
            <div>
                <h3>7 Day Average</h3>
                <p>Daily Posts: {props.stats.dailyPosts}</p>
                <p>Daily Images Posted: {props.stats.dailyImages}</p>
                <p>Daily Comments: {props.stats.dailyComments}</p>
                <p>Daily Visits: {props.stats.dailyVisits}</p>
                <p>Daily Post Edits: {props.stats.dailyEdits}</p>
                <p>Daily Post Deletions: {props.stats.dailyDeletes}</p>
                                
                <br />

                <h3>Total</h3>
                <p>Posts: {props.stats.totalPosts}</p>
                <p>Images Posted: {props.stats.totalImages}</p>
                <p>Comments: {props.stats.totalComments}</p>
                <p>Visits: {props.stats.totalVisits}</p>
                <p>Users: {props.stats.totalUsers}</p>
            </div>
            <hr />
            <h2>Site Security</h2>
            <div>
                <h3>7 Day Average</h3>
                <p>Daily Bad Login Attempts: {props.stats.dailyAttempts}</p>
            </div>
        </div>
    );
};

export default Stats;