import React, { useEffect, useState } from 'react';
import {Routes, Route, useNavigate, useLocation} from "react-router-dom"
import Landing from "./pages/Landing";
import Feed from "./pages/Feed";
import axios from 'axios';
import CreatePost from './pages/CreatePost';
import Profile from './pages/Profile';
import PostModal from './components/PostModal';
import Admin from './pages/Admin';
import StatsPage from './pages/StatsPage';
import File from './pages/Files'
import PasswordReset from './pages/PasswordReset';
import PublicPost from './pages/PublicPost';

const App = () => {
  //
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const[user, setUserData] = useState('')
  const[loading, setLoading] = useState(true)

  // Effect Hook
  useEffect(() => {
    // Make API Request
    axios({
        method: 'get',
        url: '/auth/authenticate',
        withCredentials: true,
        })
        .then(function(res) {
        if (res.status === 200) {
            // Set State
            setUserData(res.data)
            setLoading(false)

            // If on landing page, redirect to feed
            if (location.pathname === "/") {
              navigate('/feed')
            }
        }
        })
        .catch(error => {
            // If 401 Ignore
            setLoading(false)
            if (error.response.status === 401) {
                return
            }
        });
    
    // Handle Visit Logging
    const timeDiff = new Date().getTime() - sessionStorage.getItem('lastLog');
    if (!sessionStorage.getItem('lastLog') || timeDiff > 600000) {
      logVisit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function authenticate(data) {
    setUserData(data);
  }

  function logVisit() {
    axios({
      method: 'post',
      url: '/logVisit',
      withCredentials: true,
    });

    // Set Session Storage
    const time = Date.now();
    sessionStorage.setItem("lastLog", time);
  }
  
  return (
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route path="/feed" element={<Feed user={user} authenticate={authenticate} />} />
      <Route path="/newpost" element={<CreatePost user={user} authenticate={authenticate} />} />
      <Route path="/profile/:userID" element={<Profile user={user} authenticate={authenticate} />} />
      <Route path="/post/:postID" element={<PostModal user={user} authenticate={authenticate} isPage={true}/>} />
      <Route path="/admin" element={<Admin user={user} loading={loading} authenticate={authenticate} />} />
      <Route path="/stats" element={<StatsPage user={user} loading={loading} authenticate={authenticate} />} />
      <Route path="/NACS" exact element={<File />}/>
      <Route path="/reset" exact element={<PasswordReset />}/>
      <Route path="/share" exact element={<PublicPost />}/>
      </Routes>
  );
}

export default App;
