import React, {useEffect, useState} from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';

// Style & Assets
import style from '../assets/style/ManageUser.module.css';
import dots from "../assets/3dots.svg";

// Field Masking Setup
function PhoneInput(props) {
    return (
      <InputMask 
        mask='999-999-9999' 
        value={props.value} 
        onChange={props.onChange}
        autoComplete='off'/>
    );
}

const ManageUser = () => {
    const[users,setUsers] = useState([]);
    const [regions, setRegions] = useState([]);

    // Get All Users
    useEffect(() => {
        // Get Regions from API
        axios({
            method: 'get',
            url: '/getRegions',
            withCredentials: true,
        })
        .then(function(res) {
            setRegions(res.data);
        })
        .catch(error => {
            console.log(error)
        });

        axios({
            method: 'get',
            url: '/getUsers',
            withCredentials: true
        }).then(function(res) {
            console.log(res.data)
            setUsers(res.data)
        }).catch(error => {
            console.log(error)
        });
    }, [setUsers])

    function updateUser(i, key, value) {
        let temp = [...users]
        temp[i][key] = value
        setUsers(temp)
    }

    function saveUser(i) {
        axios({
            method: 'post',
            url: '/updateUser',
            withCredentials: true,
            data: 
            {
                userID: users[i].userID,
                firstName: users[i].firstName,
                lastName: users[i].lastName,
                displayName: users[i].displayName,
                jobTitle: users[i].jobTitle,
                email: users[i].email,
                phone: users[i].phone,
                region: parseInt(users[i].region),
                permissionLevel: parseInt(users[i].permissionLevel),
                commentLength: parseInt(users[i].commentLength)
            }
        }).then(function() {
            alert("User Saved")
        }).catch(error => {
            console.log(error)
            alert("There was an error:" + error)
        });
    }

    function disableUser(i) {
        if (!window.confirm("Are you sure you want to disable user: " + users[i].firstName + " " + users[i].lastName )) {
            return false
        }

        let unlockDate = window.prompt("Please enter date to auto unlock account. Leave empty for no auto unlock.", "YYYY-MM-DD");
        if (unlockDate == null) {
            return false;
        }

        axios({
            method: 'post',
            url: '/disableUser',
            withCredentials: true,
            data: 
            {
                userID: users[i].userID,
                unlockDate: unlockDate
            }
        }).then(function() {
            alert("User has been Disabled")
            window.location.reload(false);

        }).catch(error => {
            console.log(error)
            alert("There was an error: " + error)
        });
    }
    function enableUser(i) {
        if (!window.confirm("Are you sure you want to enable user: " + users[i].firstName + " " + users[i].lastName )) {
            return false
        }

        axios({
            method: 'post',
            url: '/enableUser',
            withCredentials: true,
            data: 
            {
                userID: users[i].userID,
            }
        }).then(function() {
            alert("User has been Enabled")
            // Update the Status
            window.location.reload(false);
        }).catch(error => {
            console.log(error)
            alert("There was an error: " + error)
        });
    }

    function terminateUser(i) {
        if (!window.confirm("Are you sure you want to terminate user: " + users[i].firstName + " " + users[i].lastName )) {
            return false
        }

        axios({
            method: 'post',
            url: '/terminateUser',
            withCredentials: true,
            data: 
            {
                userID: users[i].userID,
            }
        }).then(function() {
            alert("User has been Terminated")
            // Reload
            window.location.reload(false);
        }).catch(error => {
            console.log(error)
            alert("There was an error: " + error)
        });
    }

    function deleteUser(i) {
        if (!window.confirm("Are you sure you want to delete user: " + users[i].firstName + " " + users[i].lastName )) {
            return false
        }

        axios({
            method: 'post',
            url: '/deleteUser',
            withCredentials: true,
            data: 
            {
                userID: users[i].userID,
            }
        }).then(function() {
            alert("User has been deleted")
            
            let temp = [...users]
            temp.splice(i, 1)
            setUsers(temp)
        }).catch(error => {
            console.log(error)
            alert("There was an error: " + error)
        });
    }
    
    function resetPin(i) {
        axios({
            method: 'get',
            url: '/resetPinForUser',
            withCredentials: true,
            params: 
            {
                userID: users[i].userID,
            }
        }).then(function(res) {
            alert("PIN Reset - New Pin sent to user's email. New Pin = " + res.data)
        }).catch(error => {
            console.log(error)
            alert("There was an error: " + error)
        }); 
    }

    function getStatus(i) {
        if (i === 0) {
            return "Enabled"
        }
        if (i=== 1) {
            return "Disabled"
        }
        if (i===2) {
            return "Terminated"
        }
    }

    function sortTable(n) {
        var table = document.getElementById("myTable").getElementsByTagName("tbody")[0];
        var rows = table.rows;
        var switching = true;
        var dir = true;
        var switchCount = 0;
      
        while (switching) {
          switching = false;
          for (var i = 0; i < rows.length - 1; i++) {
            var shouldSwitch = false;
            var x = rows[i].getElementsByTagName("td")[n];
            var y = rows[i + 1].getElementsByTagName("td")[n];
            if (dir) {
              if (n === 0) { // for the ID column
                if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
                  shouldSwitch = true;
                  break;
                }
              } else if (n === 7) { // for the Region column
                var xValue = parseInt(x.querySelector("select").value);
                var yValue = parseInt(y.querySelector("select").value);
                if (xValue > yValue) {
                  shouldSwitch = true;
                  break;
                }
              } else { // for other columns
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                  shouldSwitch = true;
                  break;
                }
              }
            } else {
              if (n === 0) { // for the ID column
                if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
                  shouldSwitch = true;
                  break;
                }
              } else if (n === 7||8) { // for the Region column
                var xValue = parseInt(x.querySelector("select").value);
                var yValue = parseInt(y.querySelector("select").value);
                if (xValue < yValue) {
                  shouldSwitch = true;
                  break;
                }
              } else { // for other columns
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                  shouldSwitch = true;
                  break;
                }
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchCount++;
          } else {
            if (switchCount === 0 && dir) {
              dir = false;
              switching = true;
            }
          }
        }
      }
      

    return (
        <div className={style.manageUsers}>
            <h2>Manage Users</h2>
            <table id='myTable'>
                <thead>
                    <tr>
                        <th onClick={() => sortTable(0)}>ID</th>
                        <th onClick={() => sortTable(1)}>First Name</th>
                        <th onClick={() => sortTable(2)}>Last Name</th>
                        <th onClick={() => sortTable(3)}>Display Name</th>
                        <th onClick={() => sortTable(4)}>Job Title</th>
                        <th onClick={() => sortTable(5)}>Comment Length</th>
                        <th onClick={() => sortTable(6)}>Email</th>
                        <th onClick={() => sortTable(7)}>Phone</th>
                        <th onClick={() => sortTable(8)}>Region</th>
                        <th onClick={() => sortTable(9)}>Role</th>
                        <th onClick={() => sortTable(10)}>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                    <tbody id='myTable'>
                        {users.map((user, index) =>
                            <tr key={user.userID}>
                                <td>{users[index].userID}</td>
                                <td><input type="text" value={users[index].firstName} onChange={e => {updateUser(index, "firstName", e.target.value)}}/></td>
                                <td><input type="text" value={users[index].lastName} onChange={e => {updateUser(index, "lastName", e.target.value)}}/></td>
                                <td><input type="text" value={users[index].displayName} onChange={e => {updateUser(index, "displayName", e.target.value)}}/></td>
                                <td><input type="text" value={users[index].jobTitle} onChange={e => {updateUser(index, "jobTitle", e.target.value)}}/></td>
                                <td><input type="number" value={users[index].commentLength} onChange={e => {updateUser(index, "commentLength", e.target.value)}}/></td>
                                <td><input type="text" value={users[index].email} onChange={e => {updateUser(index, "email", e.target.value)}}/></td>
                                <td>
                                    <PhoneInput value={users[index].phone} onChange={e => {updateUser(index, "phone", e.target.value)}} />
                                </td>
                                <td>                
                                    <select value={users[index].region} onChange={e => updateUser(index, "region", e.target.value)}>
                                    {regions.map((region) => {
                                        return <option className='none' key={region.regionID} value={region.regionID}>{region.regionName}</option>
                                    })}
                                    </select>
                                </td>
                                <td>
                                    <select value={users[index].permissionLevel} onChange={e => updateUser(index, "permissionLevel", e.target.value)} required defaultValue={1}>
                                        <option value={0}>Guest</option>
                                        <option value={1}>Normal User</option>
                                        <option value={2}>Moderator</option>
                                        <option value={3}>Admin</option>
                                    </select>
                                </td>
                                <td>
                                    <p key={users[index].accountStatus}>{getStatus(users[index].accountStatus)}</p>
                                </td>
                                <td><button className={style.save} onClick={e => {saveUser(index)}}>Save</button></td>
                                <td>
                                    <div className={style.dropdown}>
                                        <button className={style.dropBtn}><img src={dots} alt={"menu"}/></button>
                                        <div className={style.dropdownContent}>
                                            {user.accountStatus === 1 &&
                                                <button onClick={e => {enableUser(index)}}>Enable User</button>
                                            }
                                            {user.accountStatus === 0 &&
                                                <button onClick={e => {disableUser(index)}}>Disable User</button>
                                            }
                                            {user.accountStatus !== 2 &&
                                                <button onClick={e => {terminateUser(index)}}>Terminate User</button>
                                            }
                                            <button onClick={e => {deleteUser(index)}}>Delete User</button>
                                            <button onClick={e => {resetPin(index)}}>Reset PIN</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    );
};

export default ManageUser;

