import React, {useState, useEffect, useRef} from 'react';
import FullSizeImageView from './FullSizeImageView';

// Assets & Style
import style from '../assets/style/PostImage.module.css';
import NextArrow from '../assets/next.svg';
import PrevArrow from '../assets/prev.svg';
import Loader from "react-spinners/MoonLoader";


const Spinner = () => {
    let [color] = useState('#E5E5E5');

    // Get width of  
    return (
        <div className={style.spinBox}>
            <Loader color={color} size={60}/>
        </div>
    );
};

const PostImage = (props) => {
    const[currentPhoto, setCurrentPhoto] = useState(0)
    const[showFullScreen, setShowFullScreen] = useState(false)
    const[playVideo, setPlayVideo] = useState(false)
  
    // Mobile
    let picWidth = window.innerWidth-(window.innerWidth*0.06);

    // If Desktop
    if (window.innerWidth > 800) {
        picWidth = 600;
    }

    useEffect(() => {
        // If Video, Calculate Height of Post
        if (props.type === "video") {
            // Get Aspect Ratio
            const aspectRatio =  props.width / props.height;
            console.log("Aspect Ratio: " + aspectRatio)

            // Based on picWidth, calculate height
            const height = picWidth / aspectRatio;

            // Set height of Media block
            const mediaBlock = document.getElementById('media-'+props.postID+'-block');
            if (!mediaBlock) {
                return;
            }
            console.log("Setting height to: " + height)
            mediaBlock.style.height = height+"px";
        }
    }, [props.postID, props.type, props.width, props.height, picWidth])



    function closeFullscreen() {
        setShowFullScreen(false);
    }

    // Movement Setup
    const[movement, setMovement] = useState(0)

    // Handle touch
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    function onTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function onTouchMove (e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function onTouchEnd () {
        let temp = (touchStart-touchEnd);

        // Determine if Left or right
        console.log(temp)
        if (temp > 30 ) {
            nextPhoto()
        } else if (temp < -30) {
            prevPhoto()
        } 
    }

    // Videos
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    // Video Loading
    useEffect(() => {
        if (props.type !== "video") {
            return;
        }
    
        const postRef = document.getElementById('post-' + props.postID);
        const videoRef = document.getElementById('video-' + props.postID);
    
        if (!postRef) {
            console.log("Post or video not found");
            return;
        }
    
        // Get Feed Root
        const feedRoot = document.getElementById('feed');
    
        const options = {
            root: feedRoot,
            rootMargin: '0px',
            threshold: 0.1,
        };
    
        const postObserver = new IntersectionObserver(([entry]) => {   
            if (entry.isIntersecting) {
                setIsVideoVisible(true);
            } else {
                setIsVideoVisible(false);
            }
        }, options);
    
        postObserver.observe(postRef);

        const vOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.99,
        };

        const videoObserver = new IntersectionObserver(([entry]) => {
            const videoRatio = entry.intersectionRatio;

            console.log("Video Ratio: " + videoRatio)
    
            if (entry.isIntersecting) {
                setPlayVideo(true);
            } else {
                setPlayVideo(false);
            }
        }, vOptions);
    
        videoObserver.observe(videoRef);
    
        return () => {
            if (postRef) {
                postObserver.unobserve(postRef);
            }
            if (videoRef) {
                videoObserver.unobserve(videoRef);
            }
        };
    }, [props.postID, props.type]);
    

    useEffect(() => {
        const videoRef = document.getElementById('video-' + props.postID);

        if (!videoRef) {
            console.log("Video not found")
            return;
        }
        // Set Min-width of video to picWidth
        videoRef.style.minWidth = picWidth+"px";
        
        if (isVideoVisible && playVideo) {
            videoRef.load();
            videoRef.play();
            console.log("Playing video")
        } else {
            videoRef.pause();
            videoRef.currentTime = 0;
            console.log("Pausing video")
        }
    }, [isVideoVisible, props.postID, playVideo, picWidth]);
    
    // Error Checking
    if (!props.photos) {
        return <h2>Error Loading Image</h2>
    }
    const totalPhotos = props.photos.length;

    function nextPhoto() {
        if (currentPhoto === totalPhotos-1) {
            return;
        }

        setCurrentPhoto(currentPhoto + 1)
        setMovement(picWidth*(currentPhoto+1))
    }

    function prevPhoto() {
        if (currentPhoto === 0) {
            return;
        }
        setCurrentPhoto(currentPhoto - 1)
        setMovement(picWidth*(currentPhoto-1))
    }


    function getImageURL(image) {
        // Check if image is hosted on Digital Ocean
        if (image.includes("https://")) {
            return image;
        }

        // Else image is hosted on server directly
        return process.env.REACT_APP_API_URL+"/postImages/"+image;
    }

    return (
        <div className={props.isFullscreen ? style.fullscreen : style.postImageBlockParent}>
            <div className={style.postImageBlock} id={"media-"+props.postID+"-block"}>
                <div className={style.swiper} id="imageDiv" style={{transform: `translateX(${movement * -1}px)`,}}  onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                    {props.type === "image" &&
                    <>
                        {props.photos.map((photo) =>
                        <div key={photo} className={style.imgParent} style={{minWidth: picWidth+"px"}}>
                            <img 
                                onClick={e => {setShowFullScreen(true)}}
                                src={getImageURL(photo)}
                                className={style.postImage}
                                alt="Post"
                                style={{minWidth: picWidth+"px"}}
                                />
                        </div>
                        )}
                    </>
                    }
                    {props.type === "video" &&
                        <div className={style.videoBlock}>
                            <video
                                id={'video-'+props.postID}
                                key={props.photos[0]}
                                className={style.postImage}
                                alt="Post"
                                playsInline
                                muted={props.muted}
                                controls={false}
                                controlsList=""
                                loop
                            >
                                {isVideoVisible && (
                                    <source src={getImageURL(props.photos[0])} type="video/mp4" />
                                )}
                            </video>

                            <Spinner />
                        </div>
                    }
                </div>
                {totalPhotos > 0 && currentPhoto > 0 &&
                    <button onClick={prevPhoto} className={style.prevButton}><img src={PrevArrow} alt={"Previous"} /></button>
                }
                {totalPhotos > 0 && currentPhoto < totalPhotos-1 &&
                    <button onClick={nextPhoto} className={style.nextButton}><img src={NextArrow} alt={"Next"}/></button>
                }
            </div>

            { showFullScreen &&
                <FullSizeImageView 
                imagePath={props.photos[currentPhoto]} closeModal={closeFullscreen}
                totalPhotos={totalPhotos} currentPhoto={currentPhoto}
                nextPhoto={nextPhoto} prevPhoto={prevPhoto}
                type={props.type}
                muted={props.muted}
                />
            }
        </div>
    );
};

export default PostImage;

