import { useState } from "react";
import Loader from "react-spinners/DotLoader";
import style from '../assets/style/Spinner.module.css';

const override = {
    display: "block",
    margin: "0 auto",
};


const Spinner = () => {
    let [color] = useState('#CB0303');

    return (
    <div className={style.modal}>
        <div style={{'margin-top': '10%'}}>
            <Loader color={color} cssOverride={override} size={175} className={style.modalContent} />
        </div>
    </div>);
};

export default Spinner;