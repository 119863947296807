import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/ShareComments.module.css';
import LoadNext from '../assets/loadNext.svg';
import SendImage from '../assets/send.svg';

const ShareComments = (props) => {
    const[comments, setComments] = useState([])
    const[numberOfComments, setNumberOfComments] = useState(0)
    const[commentBatchNum, setCommentBatchNum] = useState(0)
    const[maxBatch, setMaxBatch] = useState(0)
    const[comment, setComment] = useState('');
    const[showComments, setShowComments] = useState(false);

    useEffect(() => {
        // // Size Text Box
        // var text = document.getElementById(props.post.postID+'newComment');
        // text.style.height = '0';
        // text.style.height = (1+text.scrollHeight)+'px';

        // var image = document.getElementById(props.post.postID+'imageSubmit');
        // image.style.height = (1+text.scrollHeight)+'px';

        // Make API Request
        axios({
            method: 'get',
            url: '/getComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID,
                    commentBatchNum: 0
                },
            })
            .then(function(res) {
                setComments(res.data)
                getNumberOfComments()
                //
                setShowComments(true)
            })
            .catch(error => {
                console.log(error)
            });   
        // eslint-disable-next-line
    }, [props.post.postID])

    // Function to Resize Text Area
    function resize () {
        var text = document.getElementById(props.post.postID+'newComment');
        text.style.height = '0';
        text.style.height = text.scrollHeight+'px';
    }

    function getNextBatch(isNew) {
        let batchNum = commentBatchNum+1;

        if (isNew === "new") {
            batchNum = 0;
        }

        axios({
            method: 'get',
            url: '/getComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID,
                    commentBatchNum: batchNum
                },
            })
            .then(function(res) {
                if (isNew === "new") {
                    setCommentBatchNum(0)
                    setComments(res.data)
                } else {
                    let temp = comments.concat(res.data)
                    setComments(temp)
                    setCommentBatchNum(commentBatchNum+1);
                }
            })
            .catch(error => {
                console.log(error)
            });  
    }
    
    // Get the Number of Comments
    function getNumberOfComments() {
        axios({
            method: 'get',
            url: '/getNumberOfComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID
                },
            })
            .then(function(res) {
                setNumberOfComments(res.data.numberOfComments)
                setMaxBatch((res.data.numberOfComments/5)-1)
            })
            .catch(error => {
                console.log(error)
            });        
    }

 

    function formatDate(postTime) {
        
        // Input date string
        const dateString = postTime;
        // Split date and time components
        const [dateStr, timeStr] = dateString.split(' ');
        // Convert date component to ISO format
        let [month, day, year] = dateStr.split('/');
        if (month.length < 2){
          month = "0" + month;
        }
        const isoDateStr = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        // Convert time component to 24-hour format
        const [hour, minute] = timeStr.replace('PM', '').replace('AM', '').split(':');
       
        const isPm = timeStr.includes('PM');
        let militaryHour;
      
        if (hour === '12' && !isPm) {
          militaryHour = 12; // 12am exception 
        } else {
          militaryHour = isPm ? parseInt(hour, 10) + 12 : parseInt(hour, 10);
          if (militaryHour === 24) {
            militaryHour = 12; // convert 24 back to 12
          }
        }
      
        if (militaryHour < 10){
          militaryHour = "0" + militaryHour;
        }
        const isoTimeStr = `${militaryHour}:${minute}`;
        // Construct ISO datetime string
        const isoDateTimeStr = `${isoDateStr}T${isoTimeStr}:00`;
        // Parse datetime string using Date.parse() method
        const dateObj = new Date(Date.parse(isoDateTimeStr))
        // Output date object
      
        // convert string to new Date(year, monthIndex, day, hours, minutes)
   
     
        function convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        }
        const dt2 = convertTZ(new Date(), "America/Chicago");
        const diffInMs = Math.abs(dt2 - dateObj);
        const diffInSeconds = Math.floor(diffInMs / 1000)
        const diffInMinutes = Math.floor(diffInMs / (1000 *60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInDays / 365);
       
        if (diffInSeconds <= 59) {
                return "less than a minute ago";
          } else if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} `;
          } else if (diffInHours < 24) {
                return `${Math.floor(diffInHours)} hour${diffInHours > 1 ? 's' : ''} `;
          } else if (diffInDays < 7) {
                return `${diffInDays} day${diffInDays > 1 ? 's' : ''} `;
          } else if (diffInWeeks < 4) {
                return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} `;
          } else if (diffInMonths < 12) {
                return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} `;
          } else {
                return `${diffInYears} year${diffInYears > 1 ? 's' : ''} `;
        }
    } 
    
      
    
    return (
        <div>
            {(numberOfComments > 0 && !showComments) &&
                <button onClick={e => {setShowComments(true)}}>View {numberOfComments} Comments</button>
            }
            {(showComments && numberOfComments > 0)&&
                <button onClick={e => {setShowComments(false)}}>Hide Comments</button>
            }
            {(showComments && comments) &&
                <div className={style.commentDiv}>
                {comments.map((comment) =>
                    <div key={comment.commentID.toString()} className={style.commentBox}>
                        <p><a href={"/profile/"+comment.userID}><b>{comment.username}</b></a> {comment.commentText}</p>
                        <br />
                        <p>{formatDate(comment.datePosted)} ago</p>
                    </div>
                )}
                {commentBatchNum < maxBatch &&
                    <button onClick={e => {getNextBatch()}} className={style.loadNextButton}><img src={LoadNext} alt={"Load More Comments"}/></button>
                }
                </div>   
            }
        </div>
    );
};

export default ShareComments;
