import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Logout = (props) => {
    const navigate = useNavigate();

    function logout(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/auth/logout',
            withCredentials: true
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status !== 200) {                
                console.log(res.status)
            }
            navigate('/')
          });
    }
    

    return <button onClick={logout} className={props.style}>Logout</button>;
};

export default Logout;