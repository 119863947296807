import React, { useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/EditPost.module.css';

const EditPost = (props) => {
    const[newComment, setNewComment] = useState(props.caption);
    const[error, setError] = useState(false);

    function saveEdit(e) {
        e.preventDefault();

        // Update Post
        axios({
            method: 'post',
            url: '/updatePost',
            withCredentials: true,
            data: 
                {
                    postID: props.post.postID,
                    caption: newComment
                },
            })
            .then(function() {
                props.setCaption(newComment);
                props.toggleEditModal(false);
            })
            .catch(error => {
                setError(true)
            });
    }

    function cancel(e) {
        e.preventDefault();
        props.toggleEditModal(false);
    }

    return (
        <div className={style.editModal}>
            <div className={style.editModalContent}>
                <h3>Edit Caption</h3>
                <form onSubmit={saveEdit}>
                    <textarea value={newComment} onChange={e => {setNewComment(e.target.value)}} required maxLength={1024}/>
                    <div className={style.saveBox}>
                        <input type="submit" value="Save" />
                        <input type="submit" value="Cancel" className={style.cancel} onClick={e => {cancel(e)}} />
                    </div>
                </form>
                {error &&
                    <p>There has been an issue updating your post, please try again</p>
                }
            </div>
        </div>
    );
};

export default EditPost;