import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from "../assets/style/ManageRegion.module.css";

const ManageRegion = () => {
    const[regions, setRegions] = useState([]);

    useEffect(() => {
        // Get Regions from API
        axios({
            method: 'get',
            url: '/getRegions',
            withCredentials: true,
        })
        .then(function(res) {
            setRegions(res.data);
        })
        .catch(error => {
            console.log(error)
        });

    }, [])

    function addRegion(e) {
        e.preventDefault();
        const regionName = e.target[0].value;
        // Add Region to API
        axios({
            method: 'post',
            url: '/addRegion',
            withCredentials: true,
            data: {
                regionID: regions.length + 1,
                regionName: regionName,
                useAsFilter: true
            }
        })
        .then(function(res) {
            // Add Region to Regions
            let temp = [...regions]
            temp.push({
                regionID: regions.length + 1,
                regionName: regionName,
                useAsFilter: true
            })
            setRegions(temp);
            alert('Region Added')
        })
        .catch(error => {
            console.log(error)
            alert('Error Adding Region')
        });
    }

    // Delete Region from API
    function deleteRegion(regionID) {
        // Confirm Choice with User
        if (!window.confirm('Are you sure you want to delete this region?')) {
            return;
        }

        axios({
            method: 'post',
            url: '/deleteRegion',
            withCredentials: true,
            data: {
                regionID: regionID
            }
        })
        .then(function(res) {
            // Remove Region
            let temp = [...regions]
            temp = temp.filter(region => region.regionID !== regionID)
            setRegions(temp);

            alert('Region Deleted')
        })
        .catch(error => {
            console.log(error)
            alert('Error Deleting Region')
        });
    }

    // Save Region to API
    function updateRegion(regionID, regionData) {
        axios({
            method: 'post',
            url: '/updateRegion',
            withCredentials: true,
            data: {
                regionID: regionID,
                regionName: regionData.regionName,
                useAsFilter: regionData.useAsFilter
            }
        })
        .then(function(res) {
            alert('Region Updated')

            // Update Region
            let temp = [...regions]
            temp = temp.map(region => {
                if (region.regionID === regionID) {
                    region.regionName = regionData.regionName
                    region.useAsFilter = regionData.useAsFilter
                }
                return region
            })

            setRegions(temp);
        })
        .catch(error => {
            console.log(error)
            alert('Error Updating Region')
        });
    }
    
    function handleInput(e, index, field) {
        console.log(e.target.value)

        let temp = [...regions]
        // Update handling value and checkbox
        if (field === "useAsFilter") {
            temp[index].useAsFilter = !temp[index].useAsFilter
        } else {
            temp[index].regionName = e.target.value
        }

        console.log(temp[index])
        setRegions(temp)
    }

    return (
        <div className={style.manageRegions}>
            <h2>Manage Regions</h2>
            <hr />
            <table>
                <thead>
                    <tr>
                        <th>Region ID</th>
                        <th>Region Name</th>
                        <th>Use as Filter</th>
                        <th>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {regions.map((region, index) =>
                        <tr key={region.regionID}>
                            <td>{region.regionID}</td>
                            <td><input type="text" value={regions[index].regionName} onChange={e => {handleInput(e, index, "regionName")}}/></td>
                            <td><input type="checkbox" checked={regions[index].useAsFilter} onChange={e => {handleInput(e, index, "useAsFilter")}} /></td>
                            <td>
                                <button onClick={() => {updateRegion(region.regionID, regions[index])}}>Save</button>
                                <button onClick={() => {deleteRegion(region.regionID)}}>Delete</button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>


            <hr />
            <h2>Add Region</h2>
            <form onSubmit={addRegion}>
                <label>Region Name</label>
                <input type="text" placeholder='Region Name' />

                <input type="submit" value="Add Region" />
            </form>
            <br />
        </div>
    );
};

export default ManageRegion;