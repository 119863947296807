import React, {useState, useEffect, useRef } from 'react';
import axios from 'axios'

// Components
import PostImage from './PostImage';
import EditPost from './EditPost';
// Style & Assets
import style from '../assets/style/Post.module.css';
import likeButton from '../assets/like.svg';
import likedButton from '../assets/liked.svg';
import DotsImage from '../assets/3dots.svg';
import Comments from './Comments';
import PostLogModal from './PostLogModal';
import LikesModal from './LikesModal';
import PinImage from '../assets/sticky.svg';
import Bookmark from "../assets/icons/bookmark.svg";
import Share from '../assets/share.svg';
import BookmarkFilled from "../assets/icons/bookmarkFilled.svg";
import mutedImg from '../assets/icons/muted.svg';
import unmutedImg from '../assets/icons/volume.svg';
import Loader from "react-spinners/MoonLoader";


const Spinner = () => {
    let [color] = useState('#E5E5E5');

    // Get width of  
    return (
        <div>
            <Loader color={color} size={60}/>
        </div>
    );
};


const Post = (props) => {
    // State
    const[numLikes, setNumLikes] = useState(0);
    const[likeData, setLikeData] = useState({});

    const[userLiked, setUserLiked] = useState(false);
    const[userBookmarked, setUserBookmarked] = useState(false);

    const[showOptionsBox, setShowOptionsBox] = useState(false);
    const[showConfirmBox, setShowConfirmBox] = useState(false);
    const[showConfirmBox2, setShowConfirmBox2] = useState(false);

    const[stickyExpireTimestamp, setStickyExpireTimestamp] = useState('');

    const[editModal, toggleEditModal] = useState(false);
    const[caption, setCaption] = useState(props.post.caption);

    const[showPostLog, setShowPostLog] = useState(false);
    const[showLikeModal, setShowLikeModal] = useState(false);
    const[showStickyModal, setShowStickyModal] = useState(false);

    const[showTime, setShowTime] = useState(false);
    
    const[muted, setMuted] = useState(true);

    const[processing, setProcessing] = useState(true);
    
    const[photos, setPhotos] = useState(props.post.imagePaths);

    const ref = useRef(null);

    useEffect(() => {
        // If Sticky Modal is Open disable scrolling, else enable scrolling if it is disabled
        if (showStickyModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showStickyModal])

    // If Post is not processed, check every 5 seconds to see if it is processed
    useEffect(() => {
        if (!props.post.processed) {
            console.log("Checking if Post is Processed - PostID: " + props.post.postID + "")
            console.log(props.post)
            if (props.post.postID === undefined) {
                console.log("PostID is undefined. Returning")
                return;
            }

            const interval = setInterval(() => {
                axios({
                    method: 'get',
                    url: '/getPost',
                    withCredentials: true,
                    params:

                        {
                            postID: props.post.postID,
                        },
                    })
                    .then(function(res) {
                        if (res.status === 200) {
                            if (res.data) {
                                // Update State
                                if (res.data[0].processed) {
                                    console.log("Post has been processed")
                                    setPhotos(res.data[0].imagePaths)
                                    setProcessing(false);
                                    clearInterval(interval);
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }, 5000);
            return () => clearInterval(interval);
        } else {
            setProcessing(false);
        }
    }, [props.post.processed, props.post.postID, props.updatePost])

    useEffect(() => {
        // Set Sticky Expire Timestamp to current date
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();

        if (month < 10) {
            month = "0" + month;
        }

        if (day < 10) {
            day = "0" + day;
        }

        let timestamp = year + "-" + month + "-" + day;
        setStickyExpireTimestamp(timestamp)
    }, [])

    useEffect(() => {
        const modalContainer = document.getElementById('modalContainer');
        if (!modalContainer) {
            return;
        }
        console.log("Adding Event Listener")

        // modalContainer.addEventListener('click', (event) => {
        //     event.stopPropagation();
        // });

        // modalContainer.addEventListener('mousedown', (event) => {
        //     if (event.target === modalContainer) {
        //       event.preventDefault();
        //     }
        // });
    }, []);

    // Share Data
    const shareData = {
        title: props.post.caption,
        text: 'Check out this post on the Blend!',
        url: 'https://blend.xcaliberinternational.com/share?post=' + props.post.postID,
    }

    // Event Listener For Post Options menu
    useEffect(() => {
        // If the user clicks outside of the element, close the menu
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowOptionsBox(false)
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ setShowOptionsBox ]);

    // Update caption
    useEffect(() => {
        setCaption(props.post.caption)
    }, [props.post.caption])

    useEffect(() => {
        // Check if User Bookmarked Post
        if (props.post.userBookmarked) {
            setUserBookmarked(true)
        }
    }, [props.post.userBookmarked])
    

    // Get Likes
    function getLikes() {
        // Get Comments (not using function due to duplication problems)
        axios({
            method: 'get',
            url: '/getLikes',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID,
                },
            })
            .then(function(res) {
                if (res.status === 200) {
                    if (res.data) {
                        // Update State
                        if (res.data.userLiked === 1) {
                            setUserLiked(true)
                        }
                        setNumLikes(res.data.likes)
                        setLikeData(res.data.likeData)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            }); 
    }

    function likePost() {
        if (props.post.userID === props.user.userID) {
            return;
        }
        
        // Make API Request
        axios({
            method: 'post',
            url: '/likePost',
            withCredentials: true,
            data: {
                postID: props.post.postID
            }
          })
          .then(function(res) {
            // If Success, fetch comments again
            if(res.status === 200) {    
                setUserLiked(true)
                setNumLikes(numLikes+1)
            }
          })

          .catch(error => {
            // TO DO - Handle Error Message
            console.log(error.response)
          });
    }

    function removeLike() {
        // Make API Request
        axios({
            method: 'post',
            url: '/unlikePost',
            withCredentials: true,
            data: {
                postID: props.post.postID
            }
          })
          .then(function(res) {
            // If Success, fetch comments again
            if(res.status === 200) {    
                setUserLiked(false)
                setNumLikes(numLikes-1)
            }
          })
          .catch(error => {
            // TO DO - Handle Error Message
            console.log(error.response)
          });
    }

    function bookmarkPost() {
        console.log("Bookmarking Post")
        // Make API Request
        axios({
            method: 'post',
            url: '/bookmarkPost',
            withCredentials: true,
            data: {
                postID: props.post.postID
            }
          })
          .then(function(res) {
            // If Success, fetch comments again
            if(res.status === 200) {    
                setUserBookmarked(!userBookmarked)
            }
          })

          .catch(error => {
            // TO DO - Handle Error Message
            console.log(error.response)
          });
    }

    // Make Sticky
    function makeSticky(indefinite) {
        let timestamp = stickyExpireTimestamp;
        if (indefinite) {
            timestamp = '9999-12-31 23:59:59'
        } else {
            // Add hh:mm:ss to date
            timestamp = timestamp + ' 23:59:59'
        }

        // Make API Request
        axios({
            method: 'post',
            url: '/makeSticky',
            withCredentials: true,
            data: {
                postID: props.post.postID,
                stickyExpire: timestamp
            }
          })
          .then(function(res) {
            window.location.reload();
          })
          .catch(error => {
            // TO DO - Handle Error Message
            console.log(error.response)
          });
    }

    // Remove Sticky
    function removeSticky() {
        // Make API Request
        axios({
            method: 'post',
            url: '/removeSticky',
            withCredentials: true,
            data: {
                postID: props.post.postID,
            }
          })
          .then(function(res) {
            window.location.reload();
          })
          .catch(error => {
            // TO DO - Handle Error Message
            console.log(error.response)
          });
    }

    // Delete a Post
    function deletePost() {
        // Make API Request
        axios({
            method: 'post',
            url: '/deletePost',
            withCredentials: true,
            data: {
                postID: props.post.postID
            }
            })
            .then(function(res) {
            // If Success, remove post from array
            if(res.status === 200) {    
                // If Normal User
                if (props.user.permissionLevel <= 1) {
                    props.removePostFromFeed(props.post.postID)
                }

                setShowOptionsBox(false)
            }
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    function permDeletePost() {
        // Make API Request
        axios({
            method: 'post',
            url: '/permDeletePost',
            withCredentials: true,
            data: {
                postID: props.post.postID
            }
            })
            .then(function(res) {
            // If Success, remove post from array
            if(res.status === 200) {    
                props.removePostFromFeed(props.post.postID)
                setShowOptionsBox(false)
            }
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    useEffect(() => {
        getLikes();
        // eslint-disable-next-line
    }, []);


    function formatName(name) {
        // split the full name into an array of first and last name
        let names = name.split(" ");     
        // format the first and last name
        let formattedName = names[0];
        if (names.length > 1) {
            formattedName = names[0] + " " + names[1].charAt(0) + ".";   
        }
        // return the formatted name
        return formattedName;
      }


    function formatDate(postTime) {
                // Input date string
        const dateString = postTime;
        // Split date and time components
        const [dateStr, timeStr] = dateString.split(' ');
        // Convert date component to ISO format
        let [month, day, year] = dateStr.split('/');
        if (month.length < 2){
            month = "0" + month;
        }
        const isoDateStr = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        // Convert time component to 24-hour format
        const [hour, minute] = timeStr.replace('PM', '').replace('AM', '').split(':');
        const isPm = timeStr.includes('PM');
        let militaryHour;
        
        if (hour === '12' && !isPm) {
          militaryHour = 12; // 12pm exception 
        } else {
          militaryHour = isPm ? parseInt(hour, 10) + 12 : parseInt(hour, 10);
          if (militaryHour === 24) {
            militaryHour = 12; // convert 24 back to 12
          }
        }
        
        if (militaryHour < 10){
            militaryHour = "0" + militaryHour;
        }
        const isoTimeStr = `${militaryHour}:${minute}`;
        // Construct ISO datetime string
        const isoDateTimeStr = `${isoDateStr}T${isoTimeStr}:00`;
        // Parse datetime string using Date.parse() method
        const dateObj = new Date(Date.parse(isoDateTimeStr))
        // Output date object
 


        // convert string to new Date(year, monthIndex, day, hours, minutes)
        function convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        }
        const dt2 = convertTZ(new Date(), "America/Chicago");
        
        const diffInMs = Math.abs(dt2 - dateObj);
        const diffInSeconds = Math.floor(diffInMs / 1000)
        const diffInMinutes = Math.floor(diffInMs / (1000 *60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInDays / 365);
    
        if (diffInSeconds <= 59) {
            return "1 minute";
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} `;
        } else if (diffInHours < 24) {
            return `${Math.floor(diffInHours)} hour${diffInHours > 1 ? 's' : ''} `;
        } else if (diffInDays < 7) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} `;
        } else if (diffInWeeks < 4) {
            return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} `;
        } else if (diffInMonths < 12) {
        return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} `;
        } else {
            return `${diffInYears} year${diffInYears > 1 ? 's' : ''} `;
        }
    }
          

    if (!props.post.processed && props.post.userID !== props.user.userID) {
        return
    }
    
    return (
        <>
        <div className={style.post} id={"post-"+props.post.postID}>
            {showLikeModal &&
                <LikesModal likeData={likeData} setShowLikeModal={setShowLikeModal}/>
            }
            <div className={style.topBar}>
                <a className={style.profileLine} href={"/profile/"+props.post.userID}>
                    <img className={style.profilePhoto} src={process.env.REACT_APP_API_URL+"/profileImages/"+props.post.profilePhotoPath} alt={"profile"}/>
                    <h3>
                        {formatName(props.post.displayName)} 
                    </h3>
                </a>
                
                <div className={style.rightTopBar}>
                    <h4>{!props.post.archived ? props.post.jobTitle : 'ARCHIVED POST'}</h4>
                    {props.post.isSticky &&
                        <img src={PinImage} alt="Sticky Post" className={style.pinImage}/>
                    }
                    {(props.post.userID === props.user.userID || props.user.permissionLevel >= 2) &&
                        <button onClick={e => {setShowOptionsBox(!showOptionsBox)}}><img src={DotsImage} alt={"DotsImage Menu Icon"} className={style.icon}/></button>
                    }
                    {showOptionsBox &&
                        <div className={style.optionsBox} ref={ref}>
                            {(props.user.permissionLevel > 1 && !props.post.archived) &&
                                <>
                                    <button onClick={e => {setShowConfirmBox(true)}}>Archive Post</button>
                                    {!props.post.isSticky
                                        ?<button onClick={e => {setShowStickyModal(true); props.setStickyModalOpen(true)}}>Sticky Post</button>

                                        :<button onClick={e => {removeSticky()}}>Remove Sticky</button>
                                    }
                                </>
                            }
                            {(props.user.userID === props.post.userID && props.user.permissionLevel <= 1 && !props.post.isSticky) &&
                                <button onClick={e => {setShowConfirmBox(true)}}>Delete Post</button>
                            }
                            {(props.user.permissionLevel > 1 && !props.post.isSticky) &&
                                <button onClick={e => {setShowConfirmBox2(true)}}>Permanently Delete Post</button>
                            }
                            {props.post.userID === props.user.userID &&
                                <button onClick={e => {toggleEditModal(true); setShowOptionsBox(false)}}>Edit</button>
                            }
                            {props.user.permissionLevel > 1 &&
                                <button onClick={e => {setShowPostLog(true)}}>View Post Log</button>
                            }
                        </div>
                    }
                </div>
                {showConfirmBox &&
                    <div className={style.confirmBox}>
                        <h4>Are you sure you want to {props.user.permissionLevel <= 1 ? "delete" : "archive"} this post?</h4>
                        <div>
                            <button className={style.yes} onClick={e => {deletePost(); setShowConfirmBox(false)}}>Yes</button>
                            <button className={style.no} onClick={e => {setShowConfirmBox(false); setShowOptionsBox(false)}}>No</button>
                        </div>
                    </div>
                }
                {showConfirmBox2 &&
                    <div className={style.confirmBox}>
                        <h4>Are you sure you want to permanently this post?</h4>
                        <div>
                            <button className={style.yes} onClick={e => {permDeletePost(); setShowConfirmBox2(false)}}>Yes</button>
                            <button className={style.no} onClick={e => {setShowConfirmBox2(false); setShowOptionsBox(false)}}>No</button>
                        </div>
                    </div>
                }
            </div>
            { !processing
             ? <PostImage photos={photos} caption={caption} type={props.post.postType} muted={muted} postID={props.post.postID} processed={!processing} thumbnail={props.post.thumbnailPath} width={props.post.width} height={props.post.height}/>
             : <div className={style.loadingBox}>
                    <Spinner />
                </div>
            }
           
            <div className={style.bottomBar}>
                <div className={style.infoBar}>
                <div className={`${style.buttonBlock} ${props.post.userID === props.user.userID ? style.buttonBlockUser : style.buttonBlock}`}>
                    <button onClick={userLiked ? removeLike : likePost}>
                        <img src={userLiked ? likedButton : likeButton} alt={userLiked ? 'Liked' : 'Like'} />
                    </button>
                    <div className={style.likesTimeBookmark}> 
                        <div className={style.likesAndDate}>
                            <button onClick={e => {setShowLikeModal(true)}}>
                                <p>{numLikes} like{numLikes > 1|| numLikes===0 ? 's' : ''} </p>
                            </button>
 
                        </div>     
                        <div>
                        <button onClick={() => {navigator.share(shareData)}}>
                            <img src={Share} alt={"Share"} />
                        </button>

                        <button className={style.bookmarkBtn} onClick={() => {bookmarkPost()}}>
                            <img src={userBookmarked ? BookmarkFilled : Bookmark} alt={userLiked ? 'Bookmarked' : 'Bookmark'} />
                        </button>     

                        {props.post.postType === "video" &&
                            <button onClick={e => {setMuted(!muted)}}>
                                {muted ? <img src={mutedImg} alt="Muted" /> : <img src={unmutedImg} alt="UnMute" />}
                            </button>
                        }
                        </div>
                    </div>
                </div>
 
                 
            </div>
                <div className={style.dataBlock}>
                    <p>{caption}</p>
                    <div className={style.commentsBlock}>
                        <Comments user={props.user} post={props.post}  />
                    </div>
                    <p onClick={() => setShowTime(!showTime)} className={style.timestamp}>
                                {!showTime && <>{formatDate(props.post.datePosted)} ago</>}
                                {showTime && <p>{props.post.datePosted}</p>}
                    </p>   
                </div>
            </div>
            {editModal &&
                <EditPost user={props.user} post={props.post} caption={caption} toggleEditModal={toggleEditModal} setCaption={setCaption}/>
            }
            {showPostLog &&
                <PostLogModal post={props.post} setShowPostLog={setShowPostLog}/>
            }
        </div>

        {showStickyModal &&
            <div className={style.modalContainer} id="modalContainer">
                <div className={style.modalContent}>
                    <h2>Sticky Post</h2>
                    <div className={style.form}>
                        <label>Sticky Expiration Date</label>
                        <input type="date" placeholder="yyyy-mm-dd" value={stickyExpireTimestamp} onChange={e => {e.preventDefault(); setStickyExpireTimestamp(e.target.value)}} />
                        <button onClick={e => {e.preventDefault(); makeSticky(false)}}>Make Sticky with Expiration</button>
                        <button onClick={e => {e.preventDefault(); makeSticky(true)}}>Make Indefinite Sticky</button>
                        <button onClick={e => {e.preventDefault(); setShowStickyModal(false); props.setStickyModalOpen(false)}}>Cancel</button>
                    </div>
                </div>
            </div>
        }
        </>
    );
    
};

export default Post;

//<button><img src={shareButton} alt={"Share"} /></button>