import React, {useRef, useEffect} from 'react';
import disableScroll from 'disable-scroll';

// Style and Assets
import style from './../assets/style/FullSizeImageView.module.css';
import NextArrow from '../assets/next.svg';
import PrevArrow from '../assets/prev.svg';

const FullSizeImageView = (props) => {
    const ref = useRef(null);

    // Event Listener For Post Options menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.closeModal()
            }
        };

        // Get Current Center Y pixels
        let currentScrollY = window.scrollY;

        document.getElementById('modalContent').style.top = (currentScrollY-200) + 'px';
        

        //
        disableScroll.on();

        //
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            disableScroll.off();
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ props ]);

    function getImageURL(image) {
        // Check if image is hosted on Digital Ocean
        if (image.includes("https://")) {
            return image;
        }

        // Else image is hosted on server directly
        return process.env.REACT_APP_API_URL+"/postImages/"+image;
    }

    return (
        <div className={style.modal}>
            <div className={style.content} id="modalContent">
                <div ref={ref}>
                    <>
                        {props.type === "image" ?
                            <img key={props.imagePath} src={getImageURL(props.imagePath)} className={style.postImage} alt="full screen" />
                            : <video key={props.imagePath} src={getImageURL(props.imagePath)} className={style.postImage} alt="Post" autoPlay muted={props.muted} loop />
                        }
                    </>
                    {props.totalPhotos > 0 && props.currentPhoto > 0 &&
                        <button onClick={props.prevPhoto} className={style.prevButton}><img src={PrevArrow} alt={"Previous"} /></button>
                    }
                    {props.totalPhotos > 0 && props.currentPhoto < props.totalPhotos-1 &&
                        <button onClick={props.nextPhoto} className={style.nextButton}><img src={NextArrow} alt={"Next"}/></button>
                    }
                </div>
            </div>
        </div>
    );
};

export default FullSizeImageView;