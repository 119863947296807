import React, {useState, useEffect, useRef } from 'react';
import axios from 'axios'

// Components
import PostImage from '../components/PostImage';
import ShareComments from '../components/ShareComments';

// Style & Assets
import style from '../assets/style/Post.module.css';
import PinImage from '../assets/sticky.svg';
import Share from '../assets/share.svg';
import Logo from '../assets/logo.png';


const PublicPost = (props) => {
    // Get PostID from URL
    const searchParams = new URLSearchParams(window.location.search);
    const postID = searchParams.get('post');


    // State
    const[numLikes, setNumLikes] = useState(0);
    const[likeData, setLikeData] = useState({});

    const[userLiked, setUserLiked] = useState(false);
    const[userBookmarked, setUserBookmarked] = useState(false);

    const[showOptionsBox, setShowOptionsBox] = useState(false);
    const[showConfirmBox, setShowConfirmBox] = useState(false);
    const[showConfirmBox2, setShowConfirmBox2] = useState(false);

    const[loaded, setLoaded] = useState(false);

    const[caption, setCaption] = useState('');

    const[showPostLog, setShowPostLog] = useState(false);
    const[showLikeModal, setShowLikeModal] = useState(false);

    const[postData, setPostData] = useState({});

    const ref = useRef(null);

    // Share Data
    const shareData = {
        title: "Post on the Blend",
        text: 'Check out this post on the Blend!',
        url: 'https://blend.xcaliberinternational.com/share?post=' + postID,
    }

    useEffect(() => {
        // Get Post Data
        axios({
            method: 'get',
            url: '/getPost',
            withCredentials: false,
            params:
                {
                    postID: postID,
                }
        }).then(function(res) {
            getLikes();
            setPostData(res.data[0])
            setLoaded(true)
            setCaption(res.data[0].caption)
            console.log(res.data[0])
        }).catch(error => {
            console.log(error)
        });
    }, [postID])

    // Event Listener For Post Options menu
    useEffect(() => {
        // If the user clicks outside of the element, close the menu
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowOptionsBox(false)
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ setShowOptionsBox ]);

    // Get Likes
    function getLikes() {
        // Get Comments (not using function due to duplication problems)
        axios({
            method: 'get',
            url: '/getPublicLikes',
            params: 
                {
                    postID: postID,
                },
            })
            .then(function(res) {
                if (res.status === 200) {
                    if (res.data) {
                        setNumLikes(res.data.likes)
                        setLikeData(res.data.likeData)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            }); 
    }

    function formatName(name) {
        // split the full name into an array of first and last name
        let names = name.split(" ");     
        // format the first and last name
        let formattedName = names[0];
        if (names.length > 1) {
            formattedName = names[0] + " " + names[1].charAt(0) + ".";   
        }
        // return the formatted name
        return formattedName;
      }


    function formatDate(postTime) {
        // Input date string
        const dateString = postTime;
        // Split date and time components
        const [dateStr, timeStr] = dateString.split(' ');
        // Convert date component to ISO format
        let [month, day, year] = dateStr.split('/');
        if (month.length < 2){
            month = "0" + month;
        }
        const isoDateStr = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        // Convert time component to 24-hour format
        const [hour, minute] = timeStr.replace('PM', '').replace('AM', '').split(':');
        const isPm = timeStr.includes('PM');
        let militaryHour;
        
        if (hour === '12' && !isPm) {
          militaryHour = 12; // 12pm exception 
        } else {
          militaryHour = isPm ? parseInt(hour, 10) + 12 : parseInt(hour, 10);
          if (militaryHour === 24) {
            militaryHour = 12; // convert 24 back to 12
          }
        }
        
        if (militaryHour < 10){
            militaryHour = "0" + militaryHour;
        }
        const isoTimeStr = `${militaryHour}:${minute}`;
        // Construct ISO datetime string
        const isoDateTimeStr = `${isoDateStr}T${isoTimeStr}:00`;
        // Parse datetime string using Date.parse() method
        const dateObj = new Date(Date.parse(isoDateTimeStr))
        // Output date object
 


        // convert string to new Date(year, monthIndex, day, hours, minutes)
        function convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        }
        const dt2 = convertTZ(new Date(), "America/Chicago");
        
        const diffInMs = Math.abs(dt2 - dateObj);
        const diffInSeconds = Math.floor(diffInMs / 1000)
        const diffInMinutes = Math.floor(diffInMs / (1000 *60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInDays / 365);
    
        if (diffInSeconds <= 59) {
            return "less than a minute";
          } else if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} `;
          } else if (diffInHours < 24) {
                return `${Math.floor(diffInHours)} hour${diffInHours > 1 ? 's' : ''} `;
          } else if (diffInDays < 7) {
                return `${diffInDays} day${diffInDays > 1 ? 's' : ''} `;
          } else if (diffInWeeks < 4) {
                return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} `;
          } else if (diffInMonths < 12) {
                return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} `;
          } else {
                return `${diffInYears} year${diffInYears > 1 ? 's' : ''} `;
        }
    }
          

    if (!loaded) {
        return (
            <h3>Loading...</h3>
        )
    }
    
    return (
        <div className={style.postPage}>
            <a className={style.nav} href="/">
                <img src={Logo} alt={"Xcaliber International Logo"} className={style.logo} />
            </a>

            <br />

            <div className={style.post}>
                <div className={style.topBar}>
                    <span className={style.profileLine}>
                        <img className={style.profilePhoto} src={process.env.REACT_APP_API_URL+"/profileImages/"+postData.profilePhotoPath} alt={"profile"}/>
                        <h3>
                            {formatName(postData.displayName)} 
                        </h3>
                    </span>
                    
                    <div className={style.rightTopBar}>
                        <h4>{!postData.archived ? postData.jobTitle : 'ARCHIVED POST'}</h4>
                        {postData.isSticky &&
                            <img src={PinImage} alt="Sticky Post" className={style.pinImage}/>
                        }
                    </div>
                </div>

                <PostImage photos={postData.imagePaths} caption={caption} />

                <div className={style.bottomBar}>
                    <div className={style.infoBar}>
                        <div className={`${style.buttonBlock} ${style.buttonBlock}`}>
                            <div className={style.likesTimeBookmark}> 
                                <div className={style.likesAndDate}>
                                    <button>
                                        <p>{numLikes} like{numLikes > 1|| numLikes===0 ? 's' : ''} </p>
                                    </button>
                                    <p>{formatDate(postData.datePosted)} ago </p>    
                                </div>     
                                <div>
                                <button onClick={() => {navigator.share(shareData)}}>
                                    <img src={Share} alt={"Share"} />
                                </button>
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                    <div className={style.dataBlock}>
                        <p>{caption}</p>
                        <div className={style.commentsBlock}>
                            <ShareComments post={postData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default PublicPost;