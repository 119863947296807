import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/EditProfile.module.css';

const EditProfile = (props) => {
    const[profileImage, setProfileImage] = useState('');
    const[notificationsSelf, setNotificationsSelf] = useState(0);
    const[notificationsRegional, setNotificationsRegional] = useState(0);
    const[notificationsCompany, setNotificationsCompany] = useState(0);
    const[notificationsSelfSummary, setNotificationsSelfSummary] = useState(0);
    const[autoExpand, setAutoExpand] = useState(1);
    const[regions, setRegions] = useState([]);
    const[regionFilter, setRegionFilter] = useState(-1);

    // If Successful
    const[uploadSuccess, setUploadSuccess] = useState(false);
    const[prefSuccess, setPrefSuccess] = useState(false);

    // If Error
    const[uploadError, setUploadError] = useState(false);
    const[prefError, setPrefError] = useState(false);

    useEffect(() => {
        // Get Regions from API
        axios({
            method: 'get',
            url: '/getRegions',
            withCredentials: true,
        })
        .then(function(res) {
            setRegions(res.data);
        })
        .catch(error => {
            console.log(error)
        });
    }, []);

    function getProfilePrefs() {
        // Make API Request
        axios({
            method: 'get',
            url: '/getProfilePrefs',
            withCredentials: true,
            })
            .then(function(res) {
                if (res.status === 200) {
                    // Get Current Notification Settings
                    setNotificationsSelf(res.data.NotificationsSelf)
                    setNotificationsRegional(res.data.NotificationsRegional)
                    setNotificationsCompany(res.data.NotificationsCompany)
                    setNotificationsSelfSummary(res.data.NotificationsSelfSummary)
                    setRegionFilter(res.data.regionFilter)
                    let temp = 1;
                    if (!res.data.expandComments) {
                        temp = 0
                    }
                    console.log(res.data)
                    setAutoExpand(temp)
                }
            })
            .catch(error => {
                console.log(error)
            }); 
    }

    function updateProfilePrefs(e) {
        e.preventDefault();

        let temp = true;
        console.log(autoExpand)
        if (autoExpand !== 1) {
            temp = false
        }
        console.log(temp)

        // Make API Call
        axios({
            method: 'post',
            url: '/updateProfilePrefs',
            withCredentials: true,
            data: {
                NotificationsSelf: parseInt(notificationsSelf),
                notificationsSelfSummary: parseInt(notificationsSelfSummary),
                NotificationsRegional: parseInt(notificationsRegional),
                NotificationsCompany: parseInt(notificationsCompany),
                regionFilter: parseInt(regionFilter),
                expandComments: temp
            }
          })
          .then(function(res) {
            // If Success
            if(res.status === 200) {    
                props.refresh();
                setPrefSuccess(true);
                setPrefError(false);
            } else {
                setPrefSuccess(false);
                setPrefError(true);
            }
          })
          .catch(error => {
            console.log(error.response)
            setPrefSuccess(false);
            setPrefError(true);
          });
    }

    function updateProfilePhoto(e) {
        e.preventDefault();
        
        // Setup Data
        const formData = new FormData();
        formData.append("profilePhoto", profileImage)

        // API Request
        axios({
            method: 'post',
            url: '/updateProfilePhoto',
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status === 200) {    
                props.refresh();
                setUploadSuccess(true);
                setUploadError(false);
                setProfileImage(null)
            } else {
                // Handle Error
                console.log(res.status)
                setUploadSuccess(false);
                setUploadError(true);
            }
          })
          .catch(error => {
            console.log(error.response)
            setUploadSuccess(false);
            setUploadError(true);
          });
    }

    // On Load
    useEffect(() => {
        getProfilePrefs();
    }, []);

    return (
        <div className={style.profileModal}>
            <div className={style.profileModalContent}>
                <div className={style.infoBar}>
                    <button onClick={e => {props.setShowEditProfile(false)}} className={style.closeButton}>X</button>
                </div>

                <form onSubmit={updateProfilePhoto}>
                    <h3>Edit Profile</h3>
                    <label htmlFor="profilePhoto">Upload Profile Photo</label>
                    <input id="profilePhoto" type="file" required accept="image/png, image/gif, image/jpeg" onChange={e => setProfileImage(e.target.files[0])}/>

                    <input type="submit" value={"Update"}/>
                </form>
                {uploadSuccess &&
                    <p>Upload Successful!</p>
                }
                {uploadError &&
                    <p>There was a problem uploading your profile photo, please try again later</p>
                }

                <form onSubmit={updateProfilePrefs}>
                    <br />
                    <h3>Profile Settings</h3>
                    <label htmlFor="expandComments">Expand Comments by Default</label>
                    <select id="expandComments" value={autoExpand} onChange={e => {setAutoExpand(e.target.value)}}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>

                    <label htmlFor="defaultRegionFilter">Default Region Filter</label>
                    <select id="defaultRegionFilter" value={props.regionFilter} onChange={e => {setRegionFilter(e.target.value)}}>
                        <option value={-1}>All Regions</option>
                        {regions.map((region) => {
                            return <option key={region.regionID} value={region.regionID}>{region.regionName}</option>
                        })}
                    </select>


                    <label htmlFor="notificationSettingsA">Self-Post</label>
                    <select id="notificationSettingsA" value={notificationsSelf} onChange={e => {setNotificationsSelf(e.target.value)}}>
                        <option value={0}>Yes</option>
                        <option value={1}>No</option>
                    </select>

                    <label htmlFor="notificationSettingsA2">Self-Post Daily Summary</label>
                    <select id="notificationSettingsA2" value={notificationsSelfSummary} onChange={e => {setNotificationsSelfSummary(e.target.value)}}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>

                    <label htmlFor="notificationSettingsB">Regional</label>
                    <select id="notificationSettingsB" value={notificationsRegional} onChange={e => {setNotificationsRegional(e.target.value)}}>
                        <option value={0}>Every Post</option>
                        <option value={1}>Daily Summary</option>
                        <option value={2}>Weekly Summary</option>
                    </select>

                    <label htmlFor="notificationSettingsC">Company Wide</label>
                    <select id="notificationSettingsC" value={notificationsCompany} onChange={e => {setNotificationsCompany(e.target.value)}}>
                        <option value={0}>Every Post</option>
                        <option value={1}>Daily Summary</option>
                        <option value={2}>Weekly Summary</option>
                        <option value={3}>Monthly Summary</option>
                    </select>

                    <input type="submit" value={"Save"}/>
                    {prefSuccess &&
                        <p>Settings Updated!</p>
                    }
                    {prefError &&
                        <p>There was a problem updating your settings, please try again later</p>
                    }
                    
                </form>
            </div>
        </div>
    );
};

export default EditProfile;