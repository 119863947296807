import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';

// Assets & Style
import style from "../assets/style/PasswordReset.module.css";

const PasswordReset = () => {
    const[error, setError] = useState('');
    const[success, setSuccess] = useState(false);

    // PIN
    const[newPin, setNewPin] = useState('');
    const[confirmPin, setConfirmPin] = useState('');

    // Token
    const[token, setToken] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const resetToken = searchParams.get('token');

        if (!resetToken) {
            window.location.href = '/';
        }

        setToken(resetToken);
    }, []);


    function resetPassword(e) {
        e.preventDefault();

        // Check if PINs match
        if (newPin !== confirmPin) {
            setError('PINs do not match');
            return;
        }

        // Make Sure PIN is 4 digits
        if (newPin.length !== 4) {
            setError('PIN must be 4 digits');
            return;
        }

        // Make Sure PIN is only numbers
        if (isNaN(newPin)) {
            setError('PIN must be only numbers');
            return;
        }

        // Make API Request
        axios({
            method: 'post',
            url: '/resetPassword',
            withCredentials: true,
            data: {
                newPin: newPin,
                resetToken: token
            }
        }).then(res => {
            if (res.status === 200) {
                setSuccess(true);
            }
        }).catch(error => {
                setError(error.response.data.message);
            }
        );
    }
    
    return (
        <div className={style.page}>
            {!success && 
                <div>
                    <h1>Reset PIN</h1>
                    <form onSubmit={resetPassword} className={style.resetForm}>
                        <input type="password" placeholder='New PIN' minLength={4} maxLength={4} 
                            required autoComplete='new-password' ip="pin"
                            onChange={e => setNewPin(e.target.value)} />

                        <input type="password" placeholder='Confirm PIN' minLength={4} maxLength={4} 
                            required ip="pin"
                            onChange={e => setConfirmPin(e.target.value)} />

                        <input type="submit" value="Reset PIN" />
                        {error && 
                        <div>
                            <p class={style.error}>{error}</p>
                            <a href="/" className={style.return}>Return</a>
                        </div>
                        }
                    </form>
                </div>
            }

            {success &&
                <div className={style.success}>
                    <h1>Password Reset</h1>
                    <p>Your password has been reset. You can now login with your new PIN.</p>
                    <a href="/">Login</a>
                </div>
            }
        </div>
    );
};

export default PasswordReset;