import React, { useEffect } from 'react';

// Style & Assets
import style from '../assets/style/StatsTable.module.css';

const StatsTable = (props) => {
    const[data, setData] = React.useState([]);
    const[view, setView] = React.useState(1);

    const[searchText, setSearchText] = React.useState("");
    const[userStats, setUserStats] = React.useState([]);

    useEffect(() => {
        setData(props.stats)
        setUserStats(props.stats.userStats)
    }, [props.stats])  

    // Narrow Down Data
    function search(text) { 
        // Check if Text is Empty
        if (text === "") {
            setUserStats(data.userStats)
            return
        }

        // Filter Data
        let temp = data.userStats.filter((user) => {
            return user.displayName.toLowerCase().includes(text.toLowerCase())
        });

        setUserStats(temp);
    }
    
    // Sorting
    function sort(column) {
        if (view === 0 && column !== "displayName") {
            column = 'week'+column;;
        } else if (view === 1 && column !== "displayName"){
            column = 'total'+column;
        }
        
        let temp = [...userStats];

        temp.sort((a, b) => {
            if (a[column] > b[column]) {
                if (column === "displayName") {
                    return 1;
                }
                return -1;
            }
            if (a[column] < b[column]) {
                if (column === "displayName") {
                    return -1;
                }
                return 1;
            }
            return 0;
        });
        setUserStats(temp);
    }
    
    return (
        <div className={style.statsTable}>
            <div className={style.optionsBox}>
                <input type="text" placeholder='Search by Display Name' value={searchText} onChange={e => {setSearchText(e.target.value); search(e.target.value)}}/>
                <select value={view} onChange={e => {setView(parseInt(e.target.value))}}>
                    <option value={1}>Total</option>
                    <option value={0}>Last 7 Days</option>
                </select>
            </div>

            <table key={view}>
                <tr>
                    <th>UserID</th>
                    <th onClick={e => {sort("displayName")}}>Name</th>
                    <th onClick={e => {sort("Posts")}}>Posts</th>
                    <th onClick={e => {sort("Images")}}>Images</th>
                    <th onClick={e => {sort("Comments")}}>Comments</th>
                    <th onClick={e => {sort("Likes")}}>Likes</th>
                    <th onClick={e => {sort("Visits")}}>Visits</th>
                    <th onClick={e => {sort("Attempts")}}>Bad Attempts</th>
                </tr>
                {(userStats && userStats.length > 0) ?
                <tbody>
                    {userStats.map((stats) =>
                        <tr key={stats.userID}>
                            <td>{stats.userID}</td>
                            <td>{stats.displayName}</td>
                            <td>{view ? stats.totalPosts : stats.weekPosts}</td>
                            <td>{view ? stats.totalImages : stats.weekImages}</td>
                            <td>{view ? stats.totalComments : stats.weekComments}</td>
                            <td>{view ? stats.totalLikes : stats.weekLikes}</td>
                            <td>{view ? stats.totalVisits : stats.weekVisits}</td>
                            <td>{view ? stats.totalAttempts : stats.weekAttempts}</td>
                        </tr>
                    )}
                </tbody>
                :
                <>
                <h3>No Users Found</h3>
                </>
                }
            </table>
        </div>
    );
};

export default StatsTable;