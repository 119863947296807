import axios from 'axios';
import React, { useEffect, useState } from 'react';

// Style & Assets
import style from "../assets/style/PostLogModal.module.css";

const PostLogModal = (props) => {
    const[logData, setLogData] = useState([])

    useEffect(() => {
        // Make API Request
        axios({
            method: 'get',
            url: '/getLogs',
            withCredentials: true,
            params: {
                postID: parseInt(props.post.postID)
            }
        })
        .then(function(res) {
            console.log(res.data)
            setLogData(res.data)
        })
        .catch(error => {
            console.log(error.response)
        });
    },[])

    return (
        <div className={style.modal}>
            <div className={style.modalContent}>
                <div className={style.topRow}>
                    <h3>Showing post log for PostID #{props.post.postID}</h3>
                    <button onClick={e => {props.setShowPostLog(false)}}>Close Log</button>
                </div>
                <br />
                {logData ?
                <div className={style.logBox}>
                    {logData.map((logItem) =>
                        <div key={logItem.logID}>
                            <p>{logItem.timestamp} - UserID: #{logItem.userID}-{logItem.username}: {logItem.action}</p>
                        </div>
                    )}  
                </div>
                : <p>No Log Items Found</p>
                }
            </div>
        </div>
    );
};

export default PostLogModal;