import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';

// Style & Assets
import style from "../assets/style/Comments.module.css";
import LoadNext from '../assets/loadNext.svg';
import SendImage from '../assets/send.svg';

const Comments = (props) => {
    const[comments, setComments] = useState([])
    const[numberOfComments, setNumberOfComments] = useState(0)
    const[commentBatchNum, setCommentBatchNum] = useState(0)
    const[maxBatch, setMaxBatch] = useState(0)
    const[comment, setComment] = useState('');
    const[showComments, setShowComments] = useState(false);
    const[maxCommentLength, setMaxCommentLength ]= useState(256);
    
    useEffect(() => {
        // Fetch Max Comment Length
        axios({
            method: 'get',
            url: '/getMaxCommentLength',
            withCredentials: true,
            }).then(function(res) {
                if (res.data === 0) {
                    setMaxCommentLength(256)
                    return
                }

                setMaxCommentLength(res.data)
            })
            .catch(error => {
                console.log(error)
            });
            
        // Size Text Box
        let text = document.getElementById(props.post.postID+'newComment');
        text.style.height = '0';
        text.style.height = (1+text.scrollHeight)+'px';

        let image = document.getElementById(props.post.postID+'imageSubmit');
        image.style.height = (1+text.scrollHeight)+'px';

        // Make API Request
        axios({
            method: 'get',
            url: '/getComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID,
                    commentBatchNum: 0
                },
            })
            .then(function(res) {
                setComments(res.data)
                getNumberOfComments()
                //
                if (props.user.expandComments) {
                    setShowComments(true);
                }
            })
            .catch(error => {
                console.log(error)
            });   
        // eslint-disable-next-line
    }, [])

    // Function to Resize Text Area
    function resize () {
        let text = document.getElementById(props.post.postID+'newComment');
        text.style.height = '0';
        text.style.height = text.scrollHeight+'px';
    }

    // Create a Comment
    function postComment(e) {
        e.preventDefault();    

        // Make API Request
        axios({
            method: 'post',
            url: '/createComment',
            withCredentials: true,
            data: {
                commentText: comment,
                postID: props.post.postID
            }
            })
            .then(function(res) {
                getNumberOfComments()
                getNextBatch("new")
                setComment('')
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    // Delete a comment
    function deleteComment(comment) {
        // Make API Request
        axios({
            method: 'post',
            url: '/deleteComment',
            withCredentials: true,
            data: {
                postID: props.post.postID,
                commentID: comment.commentID
            }
            })
            .then(function(res) {
                // If Success, remove comment from array
                if(res.status === 200) {    
                    // Filter Feed
                    let newComments = comments.filter(function(com) { 
                        return com.commentID !== comment.commentID;  
                    });

                    // Update State
                    setComments(newComments)
                    if (newComments.length === 0) {
                        setShowComments(false)
                    }
                    getNumberOfComments();
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    function getNextBatch(isNew) {
        let batchNum = commentBatchNum+1;

        if (isNew === "new") {
            batchNum = 0;
        }

        axios({
            method: 'get',
            url: '/getComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID,
                    commentBatchNum: batchNum
                },
            })
            .then(function(res) {
                if (isNew === "new") {
                    setCommentBatchNum(0)
                    setComments(res.data)
                } else {
                    let temp = comments.concat(res.data)
                    setComments(temp)
                    setCommentBatchNum(commentBatchNum+1);
                }
            })
            .catch(error => {
                console.log(error)
            });  
    }
    
    // Get the Number of Comments
    function getNumberOfComments() {
        axios({
            method: 'get',
            url: '/getNumberOfComments',
            withCredentials: true,
            params: 
                {
                    postID: props.post.postID
                },
            })
            .then(function(res) {
                setNumberOfComments(res.data.numberOfComments)
                setMaxBatch((res.data.numberOfComments/5)-1)
            })
            .catch(error => {
                console.log(error)
            });        
    }

 

    function formatDate(postTime) {
        
        // Input date string
        const dateString = postTime;
        // Split date and time components
        const [dateStr, timeStr] = dateString.split(' ');
        // Convert date component to ISO format
        let [month, day, year] = dateStr.split('/');
        if (month.length < 2){
          month = "0" + month;
        }
        const isoDateStr = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        // Convert time component to 24-hour format
        const [hour, minute] = timeStr.replace('PM', '').replace('AM', '').split(':');
       
        const isPm = timeStr.includes('PM');
        let militaryHour;
      
        if (hour === '12' && !isPm) {
          militaryHour = 12; // 12am exception 
        } else {
          militaryHour = isPm ? parseInt(hour, 10) + 12 : parseInt(hour, 10);
          if (militaryHour === 24) {
            militaryHour = 12; // convert 24 back to 12
          }
        }
      
        if (militaryHour < 10){
          militaryHour = "0" + militaryHour;
        }
        const isoTimeStr = `${militaryHour}:${minute}`;
        // Construct ISO datetime string
        const isoDateTimeStr = `${isoDateStr}T${isoTimeStr}:00`;
        // Parse datetime string using Date.parse() method
        const dateObj = new Date(Date.parse(isoDateTimeStr))
        // Output date object
      
        // convert string to new Date(year, monthIndex, day, hours, minutes)
   
     
        function convertTZ(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        }
        const dt2 = convertTZ(new Date(), "America/Chicago");
        const diffInMs = Math.abs(dt2 - dateObj);
        const diffInSeconds = Math.floor(diffInMs / 1000)
        const diffInMinutes = Math.floor(diffInMs / (1000 *60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInDays / 365);
       
        if (diffInSeconds <= 59) {
                return "less than a minute ago";
          } else if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} `;
          } else if (diffInHours < 24) {
                return `${Math.floor(diffInHours)} hour${diffInHours > 1 ? 's' : ''} `;
          } else if (diffInDays < 7) {
                return `${diffInDays} day${diffInDays > 1 ? 's' : ''} `;
          } else if (diffInWeeks < 4) {
                return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} `;
          } else if (diffInMonths < 12) {
                return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} `;
          } else {
                return `${diffInYears} year${diffInYears > 1 ? 's' : ''} `;
        }
    }

    function sanitize(text) {
        let sanitizedCommentText = DOMPurify.sanitize(text);

        // Parse for links
        let linkRegex = /((http|https):\/\/[^\s]+)/g;
        let link = sanitizedCommentText.match(linkRegex);
        if (link) {
            sanitizedCommentText = sanitizedCommentText.replace(linkRegex, '<a href="'+link+'" target="_blank" rel="noreferrer">'+link+'</a>');
        }

        return sanitizedCommentText;
    }

    return (
        <div>
            {(numberOfComments > 0 && !showComments) &&
                <button onClick={e => {setShowComments(true)}}>View {numberOfComments} Comments</button>
            }
            {(showComments && numberOfComments > 0)&&
                <button onClick={e => {setShowComments(false)}}>Hide Comments</button>
            }
            {(showComments && comments) &&
                <div className={style.commentDiv}>
                {comments.map((comment) =>
                    <div key={comment.commentID.toString()} className={style.commentBox}>
                        <p><a href={"/profile/"+comment.userID}><b>{comment.username}</b></a>
                            <span className={style.commentText} dangerouslySetInnerHTML={{ __html: " " + sanitize(comment.commentText) }} />
                        </p>
                        <br />
                        <p>{formatDate(comment.datePosted)} ago
                            {(props.user.permissionLevel >= 2 || props.userID === comment.userID) &&
                                    <button onClick={e => {deleteComment(comment)}}><i>Remove</i></button>
                            }
                        </p>
                    </div>
                )}
                {commentBatchNum < maxBatch &&
                <button onClick={e => {getNextBatch()}} className={style.loadNextButton}><img src={LoadNext} alt={"Load More Comments"}/></button>
                }
                </div>   
            }
            <hr />
            <form className={style.commentForm} onSubmit={postComment}>
                <textarea id={props.post.postID+"newComment"} placeholder='Comment...' maxLength={maxCommentLength} value={comment} required onChange={e => {setComment(e.target.value); resize();}}/>
                <input id={props.post.postID+"imageSubmit"} type="image" src={SendImage} alt={"Post Comment"}/>
            </form>
        </div>
    );
};

export default Comments;
