import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Navbar from '../components/Navbar';
import AddUser from '../components/AddUser';
import ManageUser from '../components/ManageUser';

// Style & Assets
import style from '../assets/style/Admin.module.css';
import ManageRegion from '../components/ManageRegion';

const Admin = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        let user;
        axios({
            method: 'get',
            url: '/auth/authenticate',
            withCredentials: true,
            })
            .then(function(res) {
            if (res.status === 200) {
                // Set State
                props.authenticate(res.data)
                user = res.data;
                if (!user || user.permissionLevel < 3) {
                    navigate("/feed")
                }
            }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate("/feed")
                }
            });
    }, [navigate, props.user.permissionLevel])

    return (
        <div>
            <Navbar user={props.user} />
            <div className={style.adminDiv}>
                <h2>Admin Dashboard</h2>
                <hr />
                <ManageUser />
                <hr />
                <AddUser />
                <hr />
                <ManageRegion />
            </div>
        </div>
    );
};

export default Admin;