// Dependencies
import React, { useState } from 'react';

// Components
import Login from './Login';
import Logout from './Logout';


// Style & Assets
import style from "../assets/style/Navbar.module.css";
import Logo from "../assets/logo.png";
import Hamburger from "../assets/hamburger.svg";
import PlusImage from "../assets/plus.svg";
import HelpImg from "../assets/help.svg";
import { Link, useNavigate } from 'react-router-dom';

const Navbar = (props) => {
    //
    const navigate = useNavigate()

    // Setup State
    const[showLoginModal, setLoginModalState] = useState(false)
    const[showHamburgerMenu, setHamburgerMenuState] = useState(false)

    // Toggle the Login Modal
    function toggleLoginModal() {
        setLoginModalState(!showLoginModal)
    }

    // Toggle Hamburger Menu
    function toggleHamburgerMenu() {
        setHamburgerMenuState(!showHamburgerMenu)
    }

    //
    function newPost() {
        navigate("/newpost")
    }

    return (
        <div>
            <div className={style.navBar}>
                <Link to="/feed"><img src={Logo} alt={"Xcaliber International Logo"} className={style.logo} /></Link>
                
                {/*Desktop*/}
                <div className={style.desktopButtons}>
                    {!props.user
                    ?
                    <div className={style.notLoggedIn}>
                        <button onClick={toggleLoginModal}>Login</button>
                    </div>
                    :
                    <>
                        <a className={style.helpButton} rel="noreferrer" href="https://form.jotform.com/213145494819159" target="_blank"><img src={HelpImg} alt="Help" /></a>
                        {props.user.permissionLevel > 0 &&
                            <button className={style.createPostButton} onClick={newPost}>
                                <img src={ PlusImage} alt={"Create Post"} />
                            </button>
                        }
                        <Link to={"/profile/"+props.user.userID}><button className={style.logoutButton} >Profile</button></Link>
                        <Logout style={style.logoutButton} />
                    </>
                    }

                </div>

                {/* Mobile */}
                <div className={style.buttonRow}>
                    <button className={style.hamburgerIconButton} onClick={toggleHamburgerMenu}>
                        <img src={ Hamburger} alt={"Hamburger Menu Icon"} className={style.hamburgerIcon}/>
                    </button>
                </div>
            </div>

            {showHamburgerMenu &&
                <div >
                    {!props.user
                    ?
                    <div className={style.hamburgerMenu}>
                        <button onClick={toggleLoginModal} className={style.loginButton}>Login</button>
                    </div>
                    : 
                    <div className={style.hamburgerMenu}>
                        <Logout style={style.loginButton}/>
                        <button className={style.guestButton} onClick={e => {navigate("/profile/"+props.user.userID)}}>Profile</button>
                    </div>
                    }
                </div>
            }

            {showLoginModal &&
                <Login toggleLoginModal={toggleLoginModal} />
            }
        </div>
    );
};

export default Navbar;

// <button>Guest Sign Up</button>
// <button className={style.guestButton} onClick={e => {navigate("/profile/"+props.user.userID)}}>Profile</button>
