import React, {useRef, useEffect} from 'react';

// Style & Assets
import style from "../assets/style/LikesModal.module.css";

const LikesModal = (props) => {
    const ref = useRef(null);

    // Event Listener For Post Options menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.setShowLikeModal(false)
            }
        };

        // Disable only vertical scroll outside of modal
        document.body.style.overflowY = 'hidden';       
        document.body.style.paddingRight = '15px';

        //
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            // Enable scroll outside of modal
            document.body.style.overflowY = 'unset';
            document.body.style.paddingRight = '0px';
            
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ props ]);

    return (
        <div className={style.modal}>
            <div className={style.modalContent} ref={ref}>
                <div className={style.topRow}>
                    <h3>Liked By</h3>
                    <button onClick={e => {props.setShowLikeModal(false)}}>X</button>
                </div>
                <br />
                {props.likeData ?
                <div class={style.scroll}>
                    {props.likeData.map((like) =>
                        <div className={style.nameAndPic} key={like.likeID}>
                            <img className={style.profilePic} src={process.env.REACT_APP_API_URL+'/profileImages/'+like.profilePhotoPath} alt='profile pic' />
                            <p>{like.userDisplayName}</p>
                        </div>
                    )}  
                </div>
                : <p>No Likes Yet</p>
                }
            </div>
        </div>
    );
};

export default LikesModal;