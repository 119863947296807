import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from "../assets/style/LeftFrame.module.css";
import Bookmark from "../assets/filterIcons/bookmark.svg";
import Top from "../assets/filterIcons/top.svg";
import Search from "../assets/filterIcons/search.svg";
//import Own from "../assets/filterIcons/own.svg";
import Own from "../assets/filterIcons/mirror.svg";
import FilterIcon from "../assets/filterIcons/filter.svg";

const LeftFrame = (props) => {
    const [regions, setRegions] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        // Get Regions from API
        axios({
            method: 'get',
            url: '/getRegionsForFilter',
            withCredentials: true,
        })
        .then(function(res) {
            setRegions(res.data);
        })
        .catch(error => {
            console.log(error)
        });
    }, []);

    useEffect(() => {
        setSearchFilter(props.searchFilter)
    }, [props.searchFilter]);

    function showMoreFilters() {
        // Toggle display for .extraFilters
        let extraFilters = document.getElementById('extraFilters');
        
        if (extraFilters.style.display === 'flex') {
            extraFilters.style.display = 'none';
            return;
        }
        extraFilters.style.display = 'flex';
    }

    return (
        <div className={style.leftFrame}>
            <div className={style.subMenu}>
                <button onClick={() => {props.setFilter('top')}} className={(props.filter === 'top') ? 'active' : ''}>
                    <img src={Top} alt ="Top" />
                </button>
                
                {/* <button onClick={() => {props.setFilter('trending')}} className={(props.filter === 'trending') ? 'active' : ''}>
                    <img src={Trending} alt ="Trending" />
                </button> */}

                <button onClick={() => {props.setFilter('bookmarked')}} className={(props.filter === 'bookmarked') ? 'active' : ''}>
                    <img src={Bookmark} alt ="Bookmarked" />
                </button>

                <button onClick={() => {props.setFilter('own')}} className={(props.filter === 'own') ? 'active' : ''}>
                    <img src={Own} alt ="Own" />
                </button>

                <button onClick={() => {showMoreFilters()}} className={style.hideOnDesktop}>
                    <img src={FilterIcon} alt ="Filter" />
                </button>
            </div>
            <div className={style.extraFilters} id={"extraFilters"}>
                {/* Search Bar */}
                <form className={style.textSearch}>
                    <input type="text" onChange={(e) => {setSearchFilter(e.target.value)}} value={searchFilter} placeholder='Search...' />
                    <button onClick={(e) => {e.preventDefault(); props.setSearchFilter(searchFilter)}}>
                        <img src={Search} alt ="Search" />
                    </button>
                </form>
                {/* Drop Down of Regions */}
                <select onChange={(e) => {props.setRegionFilter(e.target.value)}} className={(props.regionFilter !== 'none') ? 'active' : ''} value={props.regionFilter}>
                    <option value="none">All Regions</option>
                    {regions.map((region) => {
                        return <option className='none' key={region.regionID} value={region.regionID}>{region.regionName}</option>
                    })}
                </select>
                {/* Drop Down of Time Periods */}
                <select onChange={(e) => {props.setTimeFilter(e.target.value)}} className={(props.timeFilter !== 'none') ? 'active' : ''} value={props.timeFilter}>
                    <option value="none">All Time</option>
                    <option value="day">1 Day</option>
                    <option value="week">1 Week</option>
                    <option value="month">1 Month</option>
                    <option value="3month">3 Months</option>
                </select>
            </div>
        </div>
    );
};

export default LeftFrame;
